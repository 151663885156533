/* ------------------------------------------------------------------- 
 * Global Styling
 * ------------------------------------------------------------------- */

html {
  scroll-behavior: smooth;
}

body {
    padding-top: $navbar-base-height;
    color: $gray-800;
}

@include media-breakpoint-up(lg) {
    body {
        padding-top: 0;
        padding-left: $sidebar-base-width;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
}

h1 {
    line-height: 1;
}

p.lead {
    font-size: 1.15rem;
    font-weight: 400;
}

.subheading {
    font-family: $headings-font-family;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.7rem;
}

.subsection {
    color: $gray-subheading;
}

.disabled {
    pointer-events: none;
    cursor: default;
}