/* ------------------------------------------------------------------- 
 * ## Images in a row
 * ------------------------------------------------------------------- */

.row {
  margin-top:1rem;
}

.row1 {
  margin-top:1rem;
}

.img img {
  width: 100%;
}

.img img {
  align: center;
}

.img p {
  text-align: center;
}

@include media-breakpoint-down(sm) {                  
  .img-1 {
    width: 100% !important;
  }
}

/* ------------------------------------------------------------------- 
 * ## Use the built-in flex modifiers instead = (12 / desired-num-columns)
 * ------------------------------------------------------------------- */

/* 
// Use: col-md-6
.column-2 {
  float: left;
  padding: 5px;
  width: 50%;
}

// Use: col-md-4
.column-3 {
  float: left;
  padding: 5px;
  width: 33.33%;
}

// Use: col-md-3
.column-4 {
  float: left;
  padding: 5px;
  width: 25%;
}

.column-5 {
  float: left;
  padding: 5px;
  width: 20%;
}

// Use: col-md-2
.column-6 {
  float: left;
  padding: 5px;
  width: 16.66%;
}
*/
