.blogPage-title {
  margin-bottom:1.3rem; 
}

.blogPage-section {
  margin-bottom:1.3rem;
}

.blogPage-section-title {
  font-family: Arial;
  text-transform: none;
  font-size: 1.3rem;
  font-weight: bold;
}

.blogPage-section-content {
}

.blogPage-section-nav {
  display: inline;
}

.blogPage-footer {
  margin-top:3.5rem;
}