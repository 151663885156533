/* ------------------------------------------------------------------- 
 * Button Styling
 * ------------------------------------------------------------------- */

.btn {
    border: 3px solid black;
    color: black;
    background-color: white;
    padding: 5px 14px;
    font-weight: bold;
    cursor: pointer;
}

.btn:hover {
    border-color: $primary;
    color: $primary;
}

.btn-index {
    font-size: 1.25rem;
}

.btn-projects {
    font-size: 14.5px;
}


/*-------- Contact --------*/

#btn-contact {
    background-color: $gray-medium;
    border: none;
    color: white;
    padding: 10px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition: 0.2s all;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    &:disabled {
        background-color: $gray-light;
        color: $gray-medium;
    }
}

#btn-contact:hover:enabled {
    background-color: $primary;
}

/*-------- Back to Top --------*/

.btn-scroll {
  display: inline-block;
  background-color: $primary;
  width: 50px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  right: 35px;
  transition: background-color .3s, opacity .4s, visibility .4s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

.btn-scroll::after {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 1.3em;
  line-height: 35px;
  color: white;
}

.btn-scroll:hover {
  cursor: pointer;
  background-color: $gray-dark;
  text-decoration: none;
}

#btn-scroll-to-top {
  bottom: 83px;
}

#btn-scroll-to-bottom {
  bottom: 35px;
}

#btn-scroll-to-top::after {
  content: "\f077";
}

#btn-scroll-to-bottom::after {
  content: "\f078";
}