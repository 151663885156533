// Override Bootstrap typography variables

$font-family-base: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-family: "Saira Extra Condensed", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-weight: 700;

$headings-color: $gray-dark;

$h1-font-size: 6rem;
$h2-font-size: 3.5rem;
$h3-font-size: 2.7rem;
$h4-font-size: 2.3rem;
$h5-font-size: 2rem;
$h6-font-size: 1.65rem;