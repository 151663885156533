/* ------------------------------------------------------------------- 
 * Project Image
 * ------------------------------------------------------------------- */

.img-project {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 1rem 0 0;
}

.img-project-pic {
    width: 210px;
    height: 210px;
}

/* ------------------------------------------------------------------- 
 * Project Image Mask
 * ------------------------------------------------------------------- */

.item-folio {
    position: relative;
    overflow: hidden;
}

.item-folio__thumb img {
    vertical-align: bottom;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.item-folio__thumb a::before {
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
    content: "";
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    z-index: 1;
}

.item-folio__thumb a::after {
    display: block;
    position: absolute;
    color: rgba(255, 255, 255, 0.8);
    visibility: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    z-index: 1;
}

.item-folio__text {
    position: absolute;
    left: 0;
    bottom: 1rem;
    padding: 0 1rem;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.item-folio__project-link {
    position: absolute;
    left: 0.35rem;
    top: 1rem;
    padding: 0 1rem;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.item-folio__title {
    font-family: "montserrat-semibold", sans-serif;
    font-size: 1rem;
    line-height: 1.286;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: .2rem;
    margin: 0 0 .3rem 0;
}

.item-folio__cat {
    color: rgba(255, 255, 255, 0.5);
    font-family: "montserrat-light", sans-serif;
    font-size: 0.8rem;
    line-height: 1.714;
    margin-bottom: 0;
}

/* ------------------------------------------------------------------- 
 * Project Image Mask (On Hover)
 * ------------------------------------------------------------------- */

.item-folio:hover .item-folio__thumb a::before {
    opacity: 1;
    visibility: visible;
}

.item-folio:hover .item-folio__thumb a::after {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.item-folio:hover .item-folio__thumb img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.item-folio:hover .item-folio__view-project,
.item-folio:hover .item-folio__project-link,
.item-folio:hover .item-folio__text {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* ------------------------------------------------------------------- 
 * Project Date
 * ------------------------------------------------------------------- */

@include media-breakpoint-down(sm) {
    .project-title {
        margin-top: 0.2em;
    }
}

@include media-breakpoint-down(md) {
    .project-date-lg-only {
        display:none;  
    }
}

@include media-breakpoint-up(lg) {
    .project-date-sm-only {
        display:none;
    }

    .project-divider {
        display:none;
    }
}


