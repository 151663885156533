// Pre-state grayscale colors used in other variables

$gray-dark: #343a40;
$gray-medium: #808080;
$gray-light: #f2f3f7;

$gray-subheading: #868e96;

// Override Bootstrap default state colors

$primary: #00BDFE; 
$primary-light: #5ADEFF;
$primary-light-author: #AADEFF;

// Override Bootstrap yiq lightness value

$yiq-contrasted-threshold: 205;


