.contact-container {
    display: flex;
    justify-content:left;
}

.contact-image {
    flex: 1;
    margin-right:20px;
    text-align: right
}  

.contact-form {
    flex: 3;
}  

@include media-breakpoint-down(lg) {                  
   .contact-image {
      display: none;
   }
}

.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    background: transparent;
    border: transparent;
    background: $gray-light;
    height: 54px;
    font-size: 16px;
    font-weight: 400;
    @include transition(.3s);
    @include border-radius(0);
    &:active, &:focus {
        outline: none;
        box-shadow: none;
        border-color:transparent;
        background: lighten(black,94%);
    }
}