.media-video {
  position: relative;
  padding-top: 56.25%;
}

.media-slides {
  position: relative; 
  padding-top: 59.27%; 
}

.media-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}