/* ------------------------------------------------------------------- 
 * Styling for the rounded social media links
 * ------------------------------------------------------------------- */

.social-icons {
    .social-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 2.4rem;
        width: 2.4rem;
        background-color: $gray-700;
        color: $white;
        border-radius: 100%;
        font-size: 1rem;
        margin-right: 0.5rem;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            background-color: lighten($primary, 20%);
        }
    }
}

@include media-breakpoint-down(md) {
    .topnav-icon {
        color: $primary-light-author;
        margin-right:15px;
    }
    .topnav-icon:hover {
        color: white;
    }
}