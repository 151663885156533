.blogPreview-title {
  color: black;
  font-weight: bold;
}

.blogPreview-title:hover {
  color: black;
  text-decoration: none;
}

.blogPreview-entry {
  
}

.blogPreview-entry .blogPreview-img {
    width: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.blogPreview-img img {
  position: relative;
  max-width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s; 
}

.blogPreview-img:hover img {
  -webkit-transform: scale(1.06);
  -moz-transform: scale(1.06);
  -ms-transform: scale(1.06);
  -o-transform: scale(1.06);
  transform: scale(1.06);
}