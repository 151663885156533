/* ------------------------------------------------------------------- 
 * Experience Styling
 * ------------------------------------------------------------------- */

.experience-centered {
    position: relative;
    margin-bottom: 30px;
}

.experience-centered:before, .experience-centered:after {
  content: " ";
  display: table;
}

.experience-centered:after {
  clear: both;
}

.experience-centered:before, .experience-centered:after {
  content: " ";
  display: table;
}

.experience-centered:after {
  clear: both;
}

.experience-centered:before {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  background: $gray-light;
  top: 20px;
  bottom: 20px;
  margin-left: 29px;
}

.experience-centered .experience-entry {
  position: relative;
  margin-top: 5px;
  margin-left: 30px;
  margin-bottom: 10px;
  clear: both;
}

.experience-centered .experience-entry .experience-entry-inner {
  position: relative;
  margin-left: -20px;
}

.experience-centered .experience-entry .experience-entry-inner .experience-icon {
  display: block;
  width: 40px;
  height: 40px;
  background: white;
  @include border-radius(50%);
  text-align: center;
  -moz-box-shadow: 0 0 0 5px $gray-light;
  -webkit-box-shadow: 0 0 0 5px $gray-light;
  box-shadow: 0 0 0 5px $gray-light;
  line-height: 40px;
  float: left;
  i{
    color: white;  
  }
  &.color-none{
    background: white;
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}

.experience-centered .experience-entry .experience-entry-inner .experience-label {
  position: relative;
  background: $gray-light;
  padding: 1.5em;
  margin-left: 60px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.experience-centered .experience-entry .experience-entry-inner .experience-label:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 9px 0;
  border-color: transparent $gray-light transparent transparent;
  left: 0;
  top: 10px;
  margin-left: -9px;
}

.experience-centered .experience-entry .experience-entry-inner .experience-label p + p {
   margin-top: 15px;
}

.experience-centered .experience-entry .experience-entry-inner .experience-label h2 {
  font-size: 2rem;
  font-weight: 500;
}

.experience-centered .experience-entry .experience-entry-inner .experience-label h2 a {
   color: black;
}

.experience-centered .experience-entry .experience-entry-inner .experience-label h2 span {
   -webkit-opacity: .4;
   -moz-opacity: .4;
   opacity: .4;
   -ms-filter: alpha(opacity=40);
   filter: alpha(opacity=40);
   font-size: 1.5rem;
}